var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.subscriptions,"single-expand":true,"item-key":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("My subscriptions")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.FromDescription))]),_c('td',[_vm._v(_vm._s(item.ToDescription))]),_c('td',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.AdminState === 'Active' ? 'green' : 'red',"label":"","text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.AdminState)+" ")])],1),_c('td',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.StartDateTime)))]),_c('td',[_c('span',[_vm._v(" "+_vm._s(item.SubscriptionTypeId)+" ")]),_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return expand(!isExpanded);}}},[_vm._v("mdi-alert-circle-outline")])],1),_c('td',_vm._l((item.Tokens),function(token){return _c('v-chip',{key:token,staticClass:"ma-2",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(token)+" ")])}),1),_c('td',[_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({name:'navsubscriptionsdetails', params: {id: '74946'}})}}},[_vm._v("Detail")])],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('tariff-tab-card',{attrs:{"code":item.SubscriptionTypeId}})],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }