


































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import SubscriptionApi from '../../services/SubscriptionApi'
import moment from 'moment'
import TariffTabCard from './TariffTabCard.vue'

// [TODO] add it to mixin ?
@Component({
  components: {
    TariffTabCard
  },
  filters: {
    formatDateTime (val: string) {
      const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
      return b
    }
  }
})
export default class Subscriptions extends Vue {
  singleSelect= false
  selected= []
  headers= [
    { text: 'From', value: 'FromDescription' },
    { text: 'To', value: 'ToDescription' },
    { text: 'State ', value: 'AdminState' },
    { text: 'Start', value: 'StartDateTime' },
    { text: 'Tariff', value: 'SubscriptionTypeId' },
    { text: 'Tokens', value: 'Tokens' },
    { text: 'Actions', value: 'action' }
  ]

  subscriptions = [
    {
      ID: '74946',
      AdminState: 'Active',
      ContractId: '',
      ContractType: 'MSP2CUST',
      CusBlueId: 4812,
      CusUserId: 1,
      DefaultTokenAllId: '6743FB19',
      EndDateTime: '',
      Id: '74946',
      fromid: '',
      fromdescription: 'BlueCorner',
      RelationContactId: '',
      RelationId: '',
      StartDateTime: '2018-04-16 17:15:52',
      SubscriptionReference: '',
      SubscriptionTypeId: 'SUB_STARTER',
      Uci: 'BCO',
      FromDescription: 'BlueCorner',
      ToDescription: 'Francis Lebaigue',
      Tokens: [
        'BE-BCA-406460-5'
      ]
    }
  ]

  mounted () {
    this.getSubscriptions()
  }

  async getSubscriptions () {
    // [TODO] Error permission denied
    /* console.log('LOAD SUBSCRIPTIONS')
    const dt = await new SubscriptionApi().getSubscriptions()
    console.log(dt) */
    // this.items = dt
    // const dt = await new RelationApi(0).relationTree({}) as any
    // console.log(dt)
    // this.items = dt
  }
}
